import { Flex, Separator, Text, Tooltip } from '@radix-ui/themes'
import { useSuspenseInfiniteQuery, useSuspenseQuery as useSuspenseTanstack } from '@tanstack/react-query'
import { Link, useSearch } from '@tanstack/react-router'
import { DateTime } from 'luxon'
import { Fragment } from 'react'
import { Avatar, Icon, Link as LinkComponent } from '~/elementsv2'
import { StatusBadge } from '~/features/hitl/components/StatusBadge'
import { isConversationStatus } from '~/features/hitl/types'
import { useConversationParticipants } from '~/hooks'
import { ThemeColor } from '~/providers/ThemeProvider'
import {
  getIntegrationByNameQueryOptions,
  listConversationsInfiniteQuery,
  listMessagesInfiniteQueryOptions,
} from '~/queries'
import { useSuspenseQuery } from '~/services'
import { cn } from '~/utils'
import type { Conversation as ConversationObject } from 'hitl-client'

type Props = {
  className?: string
  currentConversationId?: string
  downstreamConversations: ConversationObject[]
} & Parameters<typeof listConversationsInfiniteQuery>[0]

export const ConversationList = ({
  className,
  botId,
  workspaceId,
  currentConversationId,
  downstreamConversations,
  ...props
}: Props) => {
  const searchParams = useSearch({ strict: false })

  return (
    <Flex direction={'column'} gap={'1'} {...props} className={className} width={'full'}>
      {downstreamConversations.length === 0 ? (
        <Flex direction={'column'} py={'2'} px="1" gap={'1'}>
          <Text size={'2'} weight={'medium'}>
            No conversations found
          </Text>
          {Object.keys(searchParams).length > 0 ? (
            <Text size="2" className="text-gray-11">
              Try changing the filters or{' '}
              <LinkComponent
                preload={false}
                color={ThemeColor}
                to="/workspaces/$workspaceId/bots/$botId/hitl"
                params={{ botId, workspaceId }}
                search={{ ...searchParams, status: undefined, assignee: undefined }}
              >
                clearing them
              </LinkComponent>
            </Text>
          ) : (
            <Text size="2" className="text-gray-11">
              There are no conversations waiting for an agent
            </Text>
          )}
        </Flex>
      ) : (
        downstreamConversations.map((conversation, i) => (
          <ConversationListItem
            key={conversation.id}
            downstreamConversation={conversation}
            botId={botId}
            workspaceId={workspaceId}
            index={i}
            selected={currentConversationId === conversation.id}
          />
        ))
      )}
    </Flex>
  )
}

type ConversationProps = {
  botId: string
  workspaceId: string
  downstreamConversation: ConversationObject
  index: number
  selected: boolean
}

const ConversationListItem = ({ selected, index, downstreamConversation, ...workspaceAndBotId }: ConversationProps) => {
  const searchParams = useSearch({ strict: false })
  const upstreamConversationId = downstreamConversation.tags['upstream']!

  const upstreamConversation = useSuspenseQuery('workspaces_/$workspaceId_/bots_/$botId_/$conversationId_', {
    conversationId: upstreamConversationId,
    ...workspaceAndBotId,
  }).data.conversation

  const conversationIdFilter =
    downstreamConversation.status === 'pending' ? upstreamConversationId : downstreamConversation.id
  const directionFilter = downstreamConversation.status === 'pending' ? 'incoming' : 'outgoing'

  const lastUserMessage = useSuspenseInfiniteQuery(
    listMessagesInfiniteQueryOptions({
      conversationId: conversationIdFilter,
      ...workspaceAndBotId,
    })
  )
    .data.pages?.flatMap((page) => page.messages)
    .filter((message) => message.direction === directionFilter && message.type === 'text')
    .shift()

  const participants = useConversationParticipants({
    conversationId: upstreamConversationId,
    ...workspaceAndBotId,
  }).data

  const workspaceMembers = useSuspenseQuery('workspaces_/$workspaceId_/members', workspaceAndBotId).data

  // TODO: this is not ideal, this will not work with multiple participants, we assume the 1st participant is the main one
  const user = participants[0]

  const userName = user?.name ?? 'Anonymous User'
  const hitlStatus = isConversationStatus(downstreamConversation.status) ? downstreamConversation.status : 'unknown'

  const assigneeId = downstreamConversation.assignee?.workspaceMemberId
  const assignee = workspaceMembers?.find((member) => member.id === assigneeId)
  const integration = useSuspenseTanstack(
    getIntegrationByNameQueryOptions({ name: upstreamConversation.integration })
  ).data

  const lastMessageSentTimeStr = DateTime.fromISO(lastUserMessage?.createdAt ?? '')
    .toRelative({ style: 'narrow' })
    ?.replace(/\.?\sago/, '')

  return (
    <Fragment key={downstreamConversation.id}>
      {index !== 0 && <Separator size={'4'} />}
      <Link
        to="/workspaces/$workspaceId/bots/$botId/hitl"
        className={cn('p-3', selected ? 'bg-gray-3' : 'hover:bg-gray-2')}
        params={workspaceAndBotId}
        search={{ ...searchParams, conversationId: downstreamConversation.id }}
        preload={false}
      >
        <Flex gap={'2'} className="text-sm">
          <Avatar variant="soft" size={'1'} pictureUrl={user?.pictureUrl} name={userName + user?.id} radius="full" />
          <Flex direction={'column'} className="min-w-0" flexGrow={'1'} gap={'2'}>
            <Flex align={'center'} gap={'2'}>
              <Text className="truncate pr-1 font-medium">{userName}</Text>
              <Flex ml={'auto'} align={'center'} gap={'1'}>
                <StatusBadge status={hitlStatus} />
                <Tooltip content={`Conversation occurs on ${integration.name}`}>
                  <Icon
                    size="2"
                    variant={'surface'}
                    color={'gray'}
                    icon={(props) => <img src={integration.iconUrl} {...props} />}
                  />
                </Tooltip>
                {assignee && (
                  <Tooltip content={`Assigned to ${assignee.displayName}`}>
                    <Avatar
                      size={'1'}
                      className="-mt-px h-4 w-4"
                      name={assignee.email}
                      pictureUrl={assignee.profilePicture}
                    />
                  </Tooltip>
                )}
              </Flex>
            </Flex>
            <Flex align={'center'}>
              <Text size={'1'} className="line-clamp-3">
                {lastUserMessage?.payload['text'] ?? ' '}
              </Text>
              <Text size={'1'} weight={'light'} color={'gray'} ml={'auto'}>
                {lastMessageSentTimeStr}
              </Text>
            </Flex>
          </Flex>
        </Flex>
      </Link>
    </Fragment>
  )
}
